<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <div class="company-details__input_wrap">
            <InputPhone
              v-model="userFormData.ResponsiblePersonPhone"
              class="company-details__input"
              placeholder="Телефон ответственного лица"
            />
          </div>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.ResponsiblePersonFullName"
              class="company-details__input"
              placeholder="ФИО ответственного лица"
            />
          </div>
          <span>
            {{ customerData.Name }}
          </span>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">Просим по договору №</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ContractNumber"
            class="company-details__input"
            placeholder="Номер договора"
          />
        </div>
        <span class="statement-text">от</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.ContractDate"
            class="company-details__date"
          />
        </div>
        <span class="statement-text">изменить/установить переадресацию звонков с номера</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.Source"
            class="company-details__input"
            placeholder="Телефон, с которого осуществляется переадресация"
          />
        </div>
        <span class="statement-text">с</span>
        <div class="company-details__date_wrap">
          <BaseInputDate
            v-model="userFormData.ForwardingDate"
            class="company-details__date"
          />
        </div>
        <span class="statement-text"> по следующей схеме:</span>
      </div>
      <div class="dflex valign-center">
        <span class="statement-text">
          Дни недели, в которые должна идти переадресация (укажите дни недели, например,
          понедельник, вторник, пятница):
        </span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ForwardingDays"
            class="company-details__input"
            placeholder="Дни переадресации"
          />
        </div>
      </div>
      <div class="dflex valign-center">
        <span class="statement-text">
          Начало и конец периода переадресации в формате часы:минуты (например: с 9:00 до 18:00): с
        </span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ForwardingStartTime"
            class="company-details__input"
            placeholder="Укажите время в формате 00:00"
          />
        </div>
        <span class="statement-text">до</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ForwardingEndTime"
            class="company-details__input"
            placeholder="Укажите время в формате 00:00"
          />
        </div>
      </div>
      <div class="dflex valign-center">
        <span class="statement-text">Номер телефона, куда должен идти звонок/переадресация: </span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.Destination"
            class="company-details__input"
            placeholder="Телефон, на который осуществляется переадресация"
          />
        </div>
      </div>
      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="userFormData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность руководителя"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="userFormData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО руководителя"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import InputPhone from '@/components/shared/inputs/InputPhone.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { ShowCurrentDate } from '@/additional_helpers/dates';
import { mapState } from 'vuex';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  data() {
    return {
      statementTitle: 'Заявка на изменение параметров',
      userFormData: {}
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    InputPhone,
    ServicesDescription
  },

  watch: {
    customerData: {
      immediate: true,
      deep: true,
      handler() {
        this.userFormData = {
          ContractNumber: this.customerData.ContractNumber,
          ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
          ChiefPosition: this.customerData.ChiefPosition,
          ChiefFullName: this.customerData.ChiefFullName,
          ResponsiblePersonPhone: '',
          ResponsiblePersonFullName: '',
          Source: '',
          Destination: '',
          ForwardingDate: ShowCurrentDate(),
          ForwardingDays: '',
          ForwardingStartTime: '',
          ForwardingEndTime: ''
        };
      }
    }
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData
    }),

    isValid() {
      const value = Object.values(this.userFormData);
      return value.every((el) => el.length > 0);
    }
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      return {
        CurrentCustomer: {
          ContractNumber: this.userFormData.ContractNumber,
          ContractDate: `${this.userFormData.ContractDate}T00:00:00`,
          ChiefPosition: this.userFormData.ChiefPosition,
          ChiefFullName: this.userFormData.ChiefFullName
        },
        ForwardingPhone: {
          Source: this.userFormData.Source,
          Destination: this.userFormData.Destination,
          ForwardingDate: this.userFormData.ForwardingDate,
          ForwardingDays: this.userFormData.ForwardingDays,
          ForwardingStartTime: this.userFormData.ForwardingStartTime,
          ForwardingEndTime: this.userFormData.ForwardingEndTime
        },
        ResponsiblePersonPhone: this.userFormData.ResponsiblePersonPhone,
        ResponsiblePersonFullName: this.userFormData.ResponsiblePersonFullName
      };
    },

    getDocument() {
      this.createDocument(this.createModelToSend(), this.isValid);
    }
  }
};
</script>

<style></style>
